html {
  font-family: '72', '72full', Arial, Helvetica, sans-serif;
  height: 100%;
}
html body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.fd-spinner.page-load-spinner {
  margin-top: calc(50vh - 60px);
}
.fd-shell.fd-shell--fundamentals {
  position: relative;
  height: auto !important;
}
.fd-shell.fd-shell--fundamentals .fd-shell__app {
  margin-top: 0;
  min-height: calc(100vh);
  height: calc(100vh);
}
.fd-shell.fd-shell--fundamentals .fd-shell__app .fd-app > .fd-app__main > div {
  height: 100%;
}
.fd-shell.fd-shell--fundamentals .fd-shell__app .fd-app > .fd-app__main > div > div {
  height: 100%;
}
.fd-shell.fd-shell--fundamentals .fd-shell__app .fd-app > .fd-app__main > div > div > div {
  height: 100%;
}
.home-welcome-page {
  text-align: center;
}
.home-welcome-page .app {
  text-align: center;
}
.home-welcome-page .rekit-logo {
  animation: app-logo-spin infinite 10s linear;
  height: 80px;
}
.home-welcome-page .app-title {
  font-size: 1.5em;
}
.home-welcome-page .app-intro {
  font-size: large;
}
.home-welcome-page ul,
.home-welcome-page li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.home-welcome-page ul {
  margin-top: 20px;
}
.home-welcome-page li {
  margin-top: 10px;
}
.home-welcome-page a {
  color: #0288d1;
  text-decoration: none;
}
.home-welcome-page a:hover {
  text-decoration: underline;
}
.home-welcome-page p.memo {
  width: 500px;
  color: #999;
  font-size: 12px;
  line-height: 150%;
  margin: auto;
}
@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.home-page .fd-panel.icon-tile {
  cursor: pointer;
  min-width: 220px;
  height: 220px;
}
.home-page .fd-panel.icon-tile .fd-panel__header {
  border-bottom: 0;
}
.home-page .fd-panel.icon-tile .fd-panel__body {
  border-bottom: 0;
  position: relative;
  height: 170px;
}
.home-page .fd-panel.icon-tile .fd-panel__body .tile-text {
  margin-left: 22px;
  font-size: 14px;
  color: #32363a;
}
.home-page .fd-panel.icon-tile .sap-icon {
  color: #5a7ca0;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 25px;
}
.home-page .fd-panel.icon-tile .sap-icon:before {
  font-size: 36px;
}
.home-page .fd-panel__body {
  padding: 8px;
}
.home-page .fd-panel__body.no-padding {
  padding: 0;
}

